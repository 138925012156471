export default function extend(scheduler) {

/*
%e	Day of the month without leading zeros (01..31)
%d	Day of the month, 2 digits with leading zeros (01..31)
%j	Day of the year, 3 digits with leading zeros (001..366)
%a	A textual representation of a day, two letters
%W	A full textual representation of the day of the week

%c	Numeric representation of a month, without leading zeros (0..12)
%m	Numeric representation of a month, with leading zeros (00..12)
%b	A short textual representation of a month, three letters (Jan..Dec)
%M	A full textual representation of a month, such as January or March (January..December)

%y	A two digit representation of a year (93..03)
%Y	A full numeric representation of a year, 4 digits (1993..03)
*/

scheduler.config={
	default_date: "%j %M %Y",
	month_date: "%F %Y",
	load_date: "%Y-%m-%d",
	week_date: "%l",
	day_date: "%D %j",
	hour_date: "%H:%i",
	month_day: "%d",
	//xml_date: "%m/%d/%Y %H:%i",
	date_format: "%Y-%m-%d %H:%i",
	api_date: "%d-%m-%Y %H:%i",
	parse_exact_format: false,
	preserve_length:true,
	time_step: 5,
	displayed_event_color: "#ff4a4a",
	displayed_event_text_color: "#ffef80",
	wide_form: 0,
	day_column_padding: 8,
	use_select_menu_space: true,
	fix_tab_position: true,
	start_on_monday: true,
	first_hour: 0,
	last_hour: 24,
	readonly: false,
	drag_resize: true,
	drag_move: true,
	drag_create: true,
	drag_event_body: true,
	dblclick_create: true,
	details_on_dblclick: true,
	edit_on_create: true,
	details_on_create: true,
	header: null,
	hour_size_px: 44,
	resize_month_events:false,
	resize_month_timed:false,
	
	responsive_lightbox: false,
	separate_short_events: true,
	rtl:false,

	cascade_event_display: false,
	cascade_event_count: 4,
	cascade_event_margin: 30,

	multi_day:true,
	multi_day_height_limit: 200,

	drag_lightbox: true,
	preserve_scroll: true,
	select: true,

	server_utc: false,
	touch:true,
	touch_tip:true,
	touch_drag:500,
	touch_swipe_dates: false,
	quick_info_detached:true,

	positive_closing: false,
	//header_datepicker: true,
	drag_highlight: true,
	limit_drag_out: false,
	icons_edit: ["icon_save", "icon_cancel"],
	icons_select: ["icon_details", "icon_edit", "icon_delete"],
	buttons_left: ["dhx_save_btn", "dhx_cancel_btn"],
	buttons_right: ["dhx_delete_btn"],
	lightbox: {
		sections: [
			{name: "description", map_to: "text", type: "textarea", focus: true},
			{name: "time", height: 72, type: "time", map_to: "auto"}
		]
	},
	highlight_displayed_event: true,
	left_border: false,

	ajax_error: "alert",//"ignore"|"console"
	delay_render: 0,
	timeline_swap_resize: true,
	wai_aria_attributes: true,
	wai_aria_application_role: true,

	csp: "auto",
	event_attribute: "data-event-id",
	show_errors: true
};

scheduler.config.buttons_left.$initial = scheduler.config.buttons_left.join();
scheduler.config.buttons_right.$initial = scheduler.config.buttons_right.join();

scheduler._helpers = {
	parseDate: function parseDate(date) {
		var parse = scheduler.templates.xml_date || scheduler.templates.parse_date;
		return parse(date);
	},
	formatDate: function formatDate(date) {
		var format = scheduler.templates.xml_format || scheduler.templates.format_date;
		return format(date);
	}
};

scheduler.templates={};
scheduler.init_templates=function(){



	var d=scheduler.date.date_to_str;
	var c=scheduler.config;
	var f = function(a,b){
		for (var c in b)
			if (!a[c]) a[c]=b[c];
	};
	f(scheduler.templates,{
		day_date:d(c.default_date),
		month_date:d(c.month_date),
		week_date:function(d1,d2){
			if(c.rtl) {
				return scheduler.templates.day_date(scheduler.date.add(d2,-1,"day"))+" &ndash; "+scheduler.templates.day_date(d1);
			} 
			return scheduler.templates.day_date(d1)+" &ndash; "+scheduler.templates.day_date(scheduler.date.add(d2,-1,"day"));
		},
		day_scale_date:d(c.default_date),
		time_slot_text: function(date){
			return "";
		},
		time_slot_class: function(date){
			return "";
		},

		month_scale_date:d(c.week_date),
		week_scale_date:d(c.day_date),
		hour_scale:d(c.hour_date),
		time_picker:d(c.hour_date),
		event_date:d(c.hour_date),
		month_day:d(c.month_day),

		load_format: d(c.load_date),
	//	xml_date:scheduler.date.str_to_date(c.xml_date,c.server_utc),
	//	xml_format:d(c.date_format,c.server_utc),
		format_date: d(c.date_format, c.server_utc),
		parse_date:scheduler.date.str_to_date(c.date_format,c.server_utc),
		api_date:scheduler.date.str_to_date(c.api_date, false, false),
		event_header:function(start,end,ev){

			if(ev._mode === "small" || ev._mode === "smallest"){
				return scheduler.templates.event_date(start);
			}else{
				return scheduler.templates.event_date(start)+" - "+scheduler.templates.event_date(end);
			}
		},
		event_text:function(start,end,ev){
			return ev.text;
		},
		event_class:function(start,end,ev){
			return "";
		},
		month_date_class:function(d){
			return "";
		},
		week_date_class:function(d){
			return "";
		},
		event_bar_date:function(start,end,ev) {
			return scheduler.templates.event_date(start);
		},
		event_bar_text:function(start,end,ev){
			return ev.text;
		},
		month_events_link : function(date, count){
			return "<a>View more("+count+" events)</a>";
		},
		drag_marker_class : function(start, end, event){
			return "";
		},
		drag_marker_content : function(start, end, event){
			return "";
		},
		/* Could be redifined */
		tooltip_date_format: scheduler.date.date_to_str("%Y-%m-%d %H:%i"),
		tooltip_text: function(start, end, event) {
			return "<b>Event:</b> " + event.text + "<br/><b>Start date:</b> " + scheduler.templates.tooltip_date_format(start) + "<br/><b>End date:</b> " + scheduler.templates.tooltip_date_format(end);
		},

		calendar_month: d("%F %Y"),
		calendar_scale_date: d("%D"),
		calendar_date: d("%d"),
		calendar_time: d("%d-%m-%Y")

	});
	this.callEvent("onTemplatesReady",[]);
};


}